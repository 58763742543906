// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'c38727b99912f59d9f36f5d3d3e5c66a';
const css =`._panelHeader_1djhl_1{align-items:center;color:rgb(170 170 170/var(--tw-text-opacity));display:flex;flex-direction:row;font-size:.813rem;line-height:1.4;margin:-.25rem 0;padding:0}._panelHeader_1djhl_1,._panelHeader_button_1djhl_1{--tw-text-opacity:1}._panelHeader_button_1djhl_1{color:rgb(105 105 105/var(--tw-text-opacity));margin-left:auto;margin-top:-.125rem}._parametersList_1djhl_1{display:flex;flex-direction:row;flex-wrap:wrap;gap:.5rem}._parameterItem_1djhl_1{--tw-bg-opacity:1;--tw-text-opacity:1;background-color:rgb(221 221 221/var(--tw-bg-opacity));border-radius:.25rem;color:rgb(105 105 105/var(--tw-text-opacity));font-size:.813rem;line-height:1.4;padding:.25rem .5rem}._panel__options_1djhl_1{display:flex;flex-direction:column;gap:.5rem}`;
const styles = {
    get ['panelHeader']() { injectStyles(key, css, options);  return '_panelHeader_1djhl_1'; },
    get ['panelHeader_button']() { injectStyles(key, css, options);  return '_panelHeader_button_1djhl_1'; },
    get ['panelHeaderButton']() { injectStyles(key, css, options);  return '_panelHeader_button_1djhl_1'; },
    get ['parametersList']() { injectStyles(key, css, options);  return '_parametersList_1djhl_1'; },
    get ['parameterItem']() { injectStyles(key, css, options);  return '_parameterItem_1djhl_1'; },
    get ['panel__options']() { injectStyles(key, css, options);  return '_panel__options_1djhl_1'; },
    get ['panelOptions']() { injectStyles(key, css, options);  return '_panel__options_1djhl_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
