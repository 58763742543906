// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'c1c60b23f23fdedce159afa027cfd7d4';
const css =`._wrapper_zv6e7_1{max-height:100%;overflow:scroll}._createFieldSchemaForm_zv6e7_1{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity));display:flex;flex-direction:column;gap:.25rem}._createFieldSchemaForm_zv6e7_1 label{cursor:pointer;display:block;width:-moz-fit-content;width:fit-content}._createFieldSchema_zv6e7_1 h3,._createFieldSchemaForm_zv6e7_1 h3{font-size:.875rem;font-weight:700;line-height:1.4;margin-bottom:.25rem}`;
const styles = {
    get ['wrapper']() { injectStyles(key, css, options);  return '_wrapper_zv6e7_1'; },
    get ['createFieldSchemaForm']() { injectStyles(key, css, options);  return '_createFieldSchemaForm_zv6e7_1'; },
    get ['createFieldSchema']() { injectStyles(key, css, options);  return '_createFieldSchema_zv6e7_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
