// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '1e73d11c159df891e1bbd2c8e21570c8';
const css =`._genericPopup_g2b7y_1{--tw-bg-opacity:1;background-color:rgb(255 255 255/var(--tw-bg-opacity));border-radius:.25rem;cursor:auto;display:flex;margin-left:auto;margin-right:auto;margin-top:5rem;max-height:100%;position:relative;transition-duration:.25s;transition-property:width,height,max-width,max-height,min-width,min-height;transition-timing-function:cubic-bezier(.4,0,.2,1);width:100%}._genericPopup_g2b7y_1 ._genericPopup_content_g2b7y_1,._genericPopup_g2b7y_1 ._genericPopup_contentContainer_g2b7y_1{height:inherit}._genericPopup_g2b7y_1 ._genericPopup_closeButton_g2b7y_1{--tw-text-opacity:1;align-items:center;border-radius:9999px;border-width:0;color:rgb(0 0 0/var(--tw-text-opacity));display:flex;height:1.25rem;justify-content:center;margin:0;padding:0;position:absolute;right:.75rem;top:.75rem;transition-duration:.15s;transition-property:color,background-color,border-color,text-decoration-color,fill,stroke;transition-timing-function:cubic-bezier(.4,0,.2,1);width:1.25rem;z-index:50}._genericPopup_g2b7y_1 ._genericPopup_closeButton_g2b7y_1 svg{stroke-width:.1rem;height:1rem;transition-duration:.15s;transition-property:transform;transition-timing-function:cubic-bezier(.4,0,.2,1);width:1rem}._genericPopup_g2b7y_1 ._genericPopup_closeButton_g2b7y_1:hover{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}._genericPopup_g2b7y_1 ._genericPopup_closeButton_g2b7y_1:hover svg{transform:scale(120%)}._genericPopup_g2b7y_1 ._genericPopup_content_g2b7y_1:empty,._genericPopup_g2b7y_1 ._genericPopup_footer_g2b7y_1:empty,._genericPopup_g2b7y_1 ._genericPopup_header_g2b7y_1:empty,._genericPopup_g2b7y_1 ._genericPopup_notifications_g2b7y_1:empty{display:none}`;
const styles = {
    get ['genericPopup']() { injectStyles(key, css, options);  return '_genericPopup_g2b7y_1'; },
    get ['genericPopup_content']() { injectStyles(key, css, options);  return '_genericPopup_content_g2b7y_1'; },
    get ['genericPopupContent']() { injectStyles(key, css, options);  return '_genericPopup_content_g2b7y_1'; },
    get ['genericPopup_contentContainer']() { injectStyles(key, css, options);  return '_genericPopup_contentContainer_g2b7y_1'; },
    get ['genericPopupContentContainer']() { injectStyles(key, css, options);  return '_genericPopup_contentContainer_g2b7y_1'; },
    get ['genericPopup_closeButton']() { injectStyles(key, css, options);  return '_genericPopup_closeButton_g2b7y_1'; },
    get ['genericPopupCloseButton']() { injectStyles(key, css, options);  return '_genericPopup_closeButton_g2b7y_1'; },
    get ['genericPopup_footer']() { injectStyles(key, css, options);  return '_genericPopup_footer_g2b7y_1'; },
    get ['genericPopupFooter']() { injectStyles(key, css, options);  return '_genericPopup_footer_g2b7y_1'; },
    get ['genericPopup_header']() { injectStyles(key, css, options);  return '_genericPopup_header_g2b7y_1'; },
    get ['genericPopupHeader']() { injectStyles(key, css, options);  return '_genericPopup_header_g2b7y_1'; },
    get ['genericPopup_notifications']() { injectStyles(key, css, options);  return '_genericPopup_notifications_g2b7y_1'; },
    get ['genericPopupNotifications']() { injectStyles(key, css, options);  return '_genericPopup_notifications_g2b7y_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
