// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '6284d40019bfe05889ecfd976ffe968b';
const css =`._valueWrapper_ywh8w_1{--tw-text-opacity:1;color:rgb(0 0 0/var(--tw-text-opacity));display:inline-flex;max-width:100%;padding-right:.375rem;position:relative;transition:width .5s,height .5s;width:-moz-fit-content;width:fit-content}._valueWrapper_ywh8w_1[role=combobox] span:empty{--tw-bg-opacity:1;background-color:rgb(170 170 170/var(--tw-bg-opacity));min-width:7rem}._actionBar_ywh8w_1:not(._actionBar__visible_ywh8w_1){display:none}._actionBar__visible_ywh8w_1{bottom:0;left:100%;margin-right:0;position:absolute;top:0;width:0;z-index:1}`;
const styles = {
    get ['valueWrapper']() { injectStyles(key, css, options);  return '_valueWrapper_ywh8w_1'; },
    get ['actionBar']() { injectStyles(key, css, options);  return '_actionBar_ywh8w_1'; },
    get ['actionBar__visible']() { injectStyles(key, css, options);  return '_actionBar__visible_ywh8w_1'; },
    get ['actionBarVisible']() { injectStyles(key, css, options);  return '_actionBar__visible_ywh8w_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
