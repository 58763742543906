// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '789b7b5e2e749ca2ad37a1ed8fdf286b';
const css =`._editor_qmhu9_1 p{align-items:center!important;line-height:2rem!important;margin:0!important;min-height:2rem!important;padding:0!important}._editor_qmhu9_1 .tiptap{min-width:7rem}._editor_qmhu9_1 .tiptap:focus{outline:2px solid transparent;outline-offset:2px}._editor_qmhu9_1 .tiptap .is-editor-empty:first-child:before{--tw-text-opacity:1;color:rgb(170 170 170/var(--tw-text-opacity));content:attr(data-placeholder);float:left;height:0;pointer-events:none}._editor_qmhu9_1 .react-renderer{display:contents}`;
const styles = {
    get ['editor']() { injectStyles(key, css, options);  return '_editor_qmhu9_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
