// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'e3615b3a125a86a115d08b575647f939';
const css =`._formula_1goiq_1{--tw-text-opacity:1;align-items:center;border-color:transparent;border-radius:.25rem;border-width:1px;color:rgb(255 255 255/var(--tw-text-opacity));display:inline-flex;flex-direction:row;flex-wrap:nowrap;justify-content:flex-start;max-width:100%;padding:.25rem .625rem .25rem .375rem;width:-moz-fit-content;width:fit-content}._formula_1goiq_1,._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1,._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1{--tw-bg-opacity:1;background-color:rgb(13 121 194/var(--tw-bg-opacity))}._formula_1goiq_1 ._formula_1goiq_1,._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1,._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1 ._formula_1goiq_1{background-color:#3394d6}._variables_1goiq_1{-moz-column-gap:.5rem;column-gap:.5rem;flex-wrap:wrap;row-gap:.25rem}._variable_1goiq_1,._variables_1goiq_1{align-items:center;display:flex;flex-direction:row;justify-content:flex-start}._variable_1goiq_1{border-radius:.25rem;flex-grow:0;flex-shrink:1;flex-wrap:nowrap}._variable_1goiq_1 .tiptap,._variable_1goiq_1 [class*=tagPlaceholder]{--tw-bg-opacity:1;--tw-text-opacity:1;background-color:rgb(255 255 255/var(--tw-bg-opacity));border-radius:.25rem;color:rgb(0 0 0/var(--tw-text-opacity));padding:.125rem .25rem}._variable_1goiq_1 [class*=tagPlaceholder]:empty{width:4rem}._variableSvgIcon_1goiq_1{height:1.25rem;margin-right:.375rem;width:1.25rem}._addButton_1goiq_1{--tw-bg-opacity:1;--tw-text-opacity:1;align-items:center;background-color:rgb(255 255 255/var(--tw-bg-opacity));color:rgb(0 0 0/var(--tw-text-opacity));display:flex;flex-grow:0;flex-shrink:0;height:1rem;justify-content:center;margin-left:.5rem;padding:0;width:1rem}._addButton_1goiq_1:hover{--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity))}._addButton_1goiq_1 svg{height:.75rem;width:.75rem}`;
const styles = {
    get ['formula']() { injectStyles(key, css, options);  return '_formula_1goiq_1'; },
    get ['variables']() { injectStyles(key, css, options);  return '_variables_1goiq_1'; },
    get ['variable']() { injectStyles(key, css, options);  return '_variable_1goiq_1'; },
    get ['variableSvgIcon']() { injectStyles(key, css, options);  return '_variableSvgIcon_1goiq_1'; },
    get ['addButton']() { injectStyles(key, css, options);  return '_addButton_1goiq_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
