// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '52377673f8c153863193e8f06c1443f2';
const css =`._container_1pd58_1{width:100%}@media (min-width:640px){._container_1pd58_1{max-width:640px}}@media (min-width:768px){._container_1pd58_1{max-width:768px}}@media (min-width:1024px){._container_1pd58_1{max-width:1024px}}@media (min-width:1280px){._container_1pd58_1{max-width:1280px}}@media (min-width:1536px){._container_1pd58_1{max-width:1536px}}._fieldWrapper_1pd58_1{width:-moz-fit-content;width:fit-content}._fieldWrapper__disabled_1pd58_1{--tw-gradient-via-position:95%;--tw-gradient-from:#f8f8f8 var(--tw-gradient-from-position);--tw-gradient-stops:var(--tw-gradient-from),var(--tw-gradient-to);--tw-gradient-to:hsla(0,0%,97%,0) var(--tw-gradient-to-position);--tw-gradient-stops:var(--tw-gradient-from),#f8f8f8 var(--tw-gradient-via-position),var(--tw-gradient-to);--tw-gradient-to:#fff var(--tw-gradient-to-position);background-image:linear-gradient(to right,var(--tw-gradient-stops))}._fieldWrapper__clickable_1pd58_1{cursor:pointer}._fieldWrapper__clickable_1pd58_1:has(>*>.tiptap){cursor:text}._tagWrapper_1pd58_1{cursor:pointer;display:contents;line-height:1em}._innerTag_1pd58_1{align-items:center;display:inline-flex}`;
const styles = {
    get ['container']() { injectStyles(key, css, options);  return '_container_1pd58_1'; },
    get ['fieldWrapper']() { injectStyles(key, css, options);  return '_fieldWrapper_1pd58_1'; },
    get ['fieldWrapper__disabled']() { injectStyles(key, css, options);  return '_fieldWrapper__disabled_1pd58_1'; },
    get ['fieldWrapperDisabled']() { injectStyles(key, css, options);  return '_fieldWrapper__disabled_1pd58_1'; },
    get ['fieldWrapper__clickable']() { injectStyles(key, css, options);  return '_fieldWrapper__clickable_1pd58_1'; },
    get ['fieldWrapperClickable']() { injectStyles(key, css, options);  return '_fieldWrapper__clickable_1pd58_1'; },
    get ['tagWrapper']() { injectStyles(key, css, options);  return '_tagWrapper_1pd58_1'; },
    get ['innerTag']() { injectStyles(key, css, options);  return '_innerTag_1pd58_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
