// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'bba606ef0404faa5943c69b76ed11fc7';
const css =`._valueRef_13o6u_1{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}._valueRef_13o6u_1 img,._valueRef_13o6u_1 svg{display:inline-block;margin-top:-.1rem;max-height:1rem;vertical-align:middle}._valueRef_13o6u_1 img{margin-right:-.125rem}._valueRefFlex_13o6u_1{display:inline-flex;justify-content:center}._name_13o6u_1{white-space:nowrap}._valueRefTruncate_13o6u_1{align-items:center;display:flex;flex-direction:row;flex-wrap:nowrap}._truncatedSegment_13o6u_1{display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}._visibleSegment_13o6u_1{flex-grow:0;flex-shrink:0}._tagIcon_13o6u_1{display:inline-block;height:1rem;margin-right:.25rem;width:1rem}._caseContainer_13o6u_1{margin-top:.5rem}._caseContainer_13o6u_1 ._field_13o6u_1{margin-right:.625rem;position:relative}._caseContainer_13o6u_1 ._field_13o6u_1 ._deleteButton_13o6u_1{position:absolute;right:-.625rem;top:1.5rem}`;
const styles = {
    get ['valueRef']() { injectStyles(key, css, options);  return '_valueRef_13o6u_1'; },
    get ['valueRefFlex']() { injectStyles(key, css, options);  return '_valueRefFlex_13o6u_1'; },
    get ['name']() { injectStyles(key, css, options);  return '_name_13o6u_1'; },
    get ['valueRefTruncate']() { injectStyles(key, css, options);  return '_valueRefTruncate_13o6u_1'; },
    get ['truncatedSegment']() { injectStyles(key, css, options);  return '_truncatedSegment_13o6u_1'; },
    get ['visibleSegment']() { injectStyles(key, css, options);  return '_visibleSegment_13o6u_1'; },
    get ['tagIcon']() { injectStyles(key, css, options);  return '_tagIcon_13o6u_1'; },
    get ['caseContainer']() { injectStyles(key, css, options);  return '_caseContainer_13o6u_1'; },
    get ['field']() { injectStyles(key, css, options);  return '_field_13o6u_1'; },
    get ['deleteButton']() { injectStyles(key, css, options);  return '_deleteButton_13o6u_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
