// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '8d3842dc9c33045116f61fee21818312';
const css =`._truncatedWrapper_19flj_1{overflow:hidden}._truncated_19flj_1,._truncatedWrapper_19flj_1{display:inline-flex;max-width:100%}._truncated_19flj_1{flex-direction:row;flex-wrap:nowrap;width:-moz-fit-content;width:fit-content}._truncatedEnd_19flj_1{display:block}._startSegment_19flj_1,._truncatedEnd_19flj_1{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}._startSegment_19flj_1{display:inline-block;flex-grow:1}._endSegment_19flj_1{display:inline-block;flex-grow:0;flex-shrink:0}`;
const styles = {
    get ['truncatedWrapper']() { injectStyles(key, css, options);  return '_truncatedWrapper_19flj_1'; },
    get ['truncated']() { injectStyles(key, css, options);  return '_truncated_19flj_1'; },
    get ['truncatedEnd']() { injectStyles(key, css, options);  return '_truncatedEnd_19flj_1'; },
    get ['startSegment']() { injectStyles(key, css, options);  return '_startSegment_19flj_1'; },
    get ['endSegment']() { injectStyles(key, css, options);  return '_endSegment_19flj_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
