// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'c85f0543ec6a5ab6cc19d696e019c74b';
const css =`._actionBar_1fgj1_1{align-items:center;display:flex;flex-direction:row;justify-content:space-between;width:3rem}._actionBarButton_1fgj1_1{--tw-text-opacity:1;align-items:center;color:rgb(105 105 105/var(--tw-text-opacity));display:flex;flex-grow:0;flex-shrink:0;height:1.5rem;justify-content:center;width:1.5rem}._actionBarButton_1fgj1_1:hover{--tw-text-opacity:1;color:rgb(0 0 0/var(--tw-text-opacity))}._actionBarButton_1fgj1_1>svg{stroke-width:1.5px;height:1.25rem;width:1.25rem}._actionBarButton__delete_1fgj1_1{margin-left:auto}._actionBarButton__delete_1fgj1_1>svg{stroke-width:2px;height:1rem;width:1rem}`;
const styles = {
    get ['actionBar']() { injectStyles(key, css, options);  return '_actionBar_1fgj1_1'; },
    get ['actionBarButton']() { injectStyles(key, css, options);  return '_actionBarButton_1fgj1_1'; },
    get ['actionBarButton__delete']() { injectStyles(key, css, options);  return '_actionBarButton__delete_1fgj1_1'; },
    get ['actionBarButtonDelete']() { injectStyles(key, css, options);  return '_actionBarButton__delete_1fgj1_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
