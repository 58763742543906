// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '5598c1d82aab4d8ed14437e0476e4f95';
const css =`@layer elements{._hbox_k0rs0_1,._vbox_k0rs0_1{--pane-padding-x:initial;--pane-padding-x-100:2.25rem;--pane-padding-x-90:1.25rem}._hbox_k0rs0_1{flex-direction:row}._hbox_k0rs0_1,._vbox_k0rs0_1{align-items:flex-start;display:flex;height:100%;justify-content:flex-start;max-height:100%;max-width:100%;width:100%}._vbox_k0rs0_1{flex-direction:column}._pane_k0rs0_1{flex-grow:0;flex-shrink:0;padding-left:var(--pane-padding-x);padding-right:var(--pane-padding-x)}._pane_k0rs0_1._pane__stretch_k0rs0_1{flex-grow:1;flex-shrink:1}._pane_k0rs0_1._pane__scrollable_k0rs0_1,._pane_k0rs0_1._pane__stretch_k0rs0_1{overflow:auto}._hbox_k0rs0_1>._pane_k0rs0_1{height:100%;max-width:100%}._vbox_k0rs0_1>._pane_k0rs0_1{max-height:100%;width:100%}}`;
const styles = {
    get ['hbox']() { injectStyles(key, css, options);  return '_hbox_k0rs0_1'; },
    get ['vbox']() { injectStyles(key, css, options);  return '_vbox_k0rs0_1'; },
    get ['pane']() { injectStyles(key, css, options);  return '_pane_k0rs0_1'; },
    get ['pane__stretch']() { injectStyles(key, css, options);  return '_pane__stretch_k0rs0_1'; },
    get ['paneStretch']() { injectStyles(key, css, options);  return '_pane__stretch_k0rs0_1'; },
    get ['pane__scrollable']() { injectStyles(key, css, options);  return '_pane__scrollable_k0rs0_1'; },
    get ['paneScrollable']() { injectStyles(key, css, options);  return '_pane__scrollable_k0rs0_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
