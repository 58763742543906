// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'b82df15aecfe3a50e51fa62b1c3c2c15';
const css =`._fieldType_1ycyg_1{align-items:center;border-radius:.25rem;color:#83ad09;cursor:pointer;display:inline-flex;padding-left:.375rem;padding-right:.375rem}button._fieldType_1ycyg_1{margin-top:-1px}button._fieldType_1ycyg_1:focus{outline:2px solid transparent;outline-offset:2px}button._fieldType_1ycyg_1:focus,button._fieldType_1ycyg_1:hover{--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity))}._readOnly_1ycyg_1{cursor:default}._fieldType_1ycyg_1 svg{stroke:#000;stroke-width:1.5;height:.75rem;margin-left:.25rem;margin-top:.2rem;width:.75rem}._fieldType__string_1ycyg_1{color:#83ad09}._fieldType__number_1ycyg_1{color:#5d7a06}._fieldType__boolean_1ycyg_1{color:#c75300}._fieldType__object_1ycyg_1{color:#fabb66}._fieldType__connection_1ycyg_1{color:#0c367a}._fieldType__request_1ycyg_1{color:#0b97e3}._fieldType__array_1ycyg_1{color:#7e6edb}`;
const styles = {
    get ['fieldType']() { injectStyles(key, css, options);  return '_fieldType_1ycyg_1'; },
    get ['readOnly']() { injectStyles(key, css, options);  return '_readOnly_1ycyg_1'; },
    get ['fieldType__string']() { injectStyles(key, css, options);  return '_fieldType__string_1ycyg_1'; },
    get ['fieldTypeString']() { injectStyles(key, css, options);  return '_fieldType__string_1ycyg_1'; },
    get ['fieldType__number']() { injectStyles(key, css, options);  return '_fieldType__number_1ycyg_1'; },
    get ['fieldTypeNumber']() { injectStyles(key, css, options);  return '_fieldType__number_1ycyg_1'; },
    get ['fieldType__boolean']() { injectStyles(key, css, options);  return '_fieldType__boolean_1ycyg_1'; },
    get ['fieldTypeBoolean']() { injectStyles(key, css, options);  return '_fieldType__boolean_1ycyg_1'; },
    get ['fieldType__object']() { injectStyles(key, css, options);  return '_fieldType__object_1ycyg_1'; },
    get ['fieldTypeObject']() { injectStyles(key, css, options);  return '_fieldType__object_1ycyg_1'; },
    get ['fieldType__connection']() { injectStyles(key, css, options);  return '_fieldType__connection_1ycyg_1'; },
    get ['fieldTypeConnection']() { injectStyles(key, css, options);  return '_fieldType__connection_1ycyg_1'; },
    get ['fieldType__request']() { injectStyles(key, css, options);  return '_fieldType__request_1ycyg_1'; },
    get ['fieldTypeRequest']() { injectStyles(key, css, options);  return '_fieldType__request_1ycyg_1'; },
    get ['fieldType__array']() { injectStyles(key, css, options);  return '_fieldType__array_1ycyg_1'; },
    get ['fieldTypeArray']() { injectStyles(key, css, options);  return '_fieldType__array_1ycyg_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
