// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '9ebb8ef59d9080d10f52ca02219ac94d';
const css =`._fieldWrapper_1cz75_1{align-items:baseline;display:flex;flex-direction:row}._fieldKey__static_1cz75_1{cursor:default}._fieldValue__invalid_1cz75_1,._notification_1cz75_1{--tw-text-opacity:1;color:rgb(168 54 5/var(--tw-text-opacity))}._notification_1cz75_1{margin-left:-.5rem;width:.5rem}`;
const styles = {
    get ['fieldWrapper']() { injectStyles(key, css, options);  return '_fieldWrapper_1cz75_1'; },
    get ['fieldKey__static']() { injectStyles(key, css, options);  return '_fieldKey__static_1cz75_1'; },
    get ['fieldKeyStatic']() { injectStyles(key, css, options);  return '_fieldKey__static_1cz75_1'; },
    get ['fieldValue__invalid']() { injectStyles(key, css, options);  return '_fieldValue__invalid_1cz75_1'; },
    get ['fieldValueInvalid']() { injectStyles(key, css, options);  return '_fieldValue__invalid_1cz75_1'; },
    get ['notification']() { injectStyles(key, css, options);  return '_notification_1cz75_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
