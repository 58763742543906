// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'c9b5b42c6d59abb989fb9d32b1af937d';
const css =`._formulaConfig__header_1cym7_1{flex-shrink:0;padding:.5rem .5rem .5rem 1rem}._formulaConfig__panel_1cym7_1{padding:.5rem 1rem}._formulaConfig__panelContent_1cym7_1{flex:1 1 0%;min-height:0;overflow-x:auto;width:min(30rem,70vw)}._formulaConfig__footer_1cym7_1{--tw-bg-opacity:1;align-items:flex-start;background-color:rgb(238 238 238/var(--tw-bg-opacity));flex-direction:row;flex-shrink:0;padding:.5rem .5rem .5rem 1rem}`;
const styles = {
    get ['formulaConfig__header']() { injectStyles(key, css, options);  return '_formulaConfig__header_1cym7_1'; },
    get ['formulaConfigHeader']() { injectStyles(key, css, options);  return '_formulaConfig__header_1cym7_1'; },
    get ['formulaConfig__panel']() { injectStyles(key, css, options);  return '_formulaConfig__panel_1cym7_1'; },
    get ['formulaConfigPanel']() { injectStyles(key, css, options);  return '_formulaConfig__panel_1cym7_1'; },
    get ['formulaConfig__panelContent']() { injectStyles(key, css, options);  return '_formulaConfig__panelContent_1cym7_1'; },
    get ['formulaConfigPanelContent']() { injectStyles(key, css, options);  return '_formulaConfig__panelContent_1cym7_1'; },
    get ['formulaConfig__footer']() { injectStyles(key, css, options);  return '_formulaConfig__footer_1cym7_1'; },
    get ['formulaConfigFooter']() { injectStyles(key, css, options);  return '_formulaConfig__footer_1cym7_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
