// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '7a1b187cc9dc5c47a31e79c58ef68296';
const css =`._panel_1hycc_1{display:flex;flex-direction:column;gap:.5rem;padding:.5rem}._panel__collapsed_1hycc_1{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}._panel__expanded_1hycc_1{padding-bottom:.75rem}`;
const styles = {
    get ['panel']() { injectStyles(key, css, options);  return '_panel_1hycc_1'; },
    get ['panel__collapsed']() { injectStyles(key, css, options);  return '_panel__collapsed_1hycc_1'; },
    get ['panelCollapsed']() { injectStyles(key, css, options);  return '_panel__collapsed_1hycc_1'; },
    get ['panel__expanded']() { injectStyles(key, css, options);  return '_panel__expanded_1hycc_1'; },
    get ['panelExpanded']() { injectStyles(key, css, options);  return '_panel__expanded_1hycc_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
