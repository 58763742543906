// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '55d6bbc7a2e5d3e3d66f86043d7b9e20';
const css =`._wrapper_1vc4s_1{margin-left:-1.25rem;padding-bottom:.5rem;width:100%}`;
const styles = {
    get ['wrapper']() { injectStyles(key, css, options);  return '_wrapper_1vc4s_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
