// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '928510088e4cd292fca988ac47a3fd80';
const css =`._popper_1cc3s_1{display:flex;flex-direction:column;max-width:-moz-fit-content;max-width:fit-content;min-width:22rem;will-change:transform,width,max-height;z-index:600}._popper_1cc3s_1[data-popper-reference-hidden=true]{pointer-events:none;visibility:hidden}._popper_1cc3s_1[data-popper-placement^=top]{justify-content:flex-end;margin-bottom:20px;margin-left:-9px}._popper_1cc3s_1[data-popper-placement^=bottom]{justify-content:flex-start}`;
const styles = {
    get ['popper']() { injectStyles(key, css, options);  return '_popper_1cc3s_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
