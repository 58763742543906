// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '6fc8b892aee5ef7d2fd6e560c7711024';
const css =`._dropdownElement_tabIcon_196xa_1{display:inline-block;height:1rem;width:1rem}._dropdownElement_itemChevron_196xa_1,._dropdownElement_itemIcon_196xa_1{flex-grow:0;flex-shrink:0;height:1rem;width:1rem}._dropdownElement_itemChevron_196xa_1{margin-left:.25rem}._dropdownElement_itemTitle_196xa_1{display:flex;flex-direction:column;flex-grow:1;gap:.125rem;max-width:100%;overflow-wrap:break-word}._dropdownElement_itemTitle_196xa_1>small{font-size:.813rem;font-style:italic;line-height:1.2}._dropdownElement_itemCustomValue_196xa_1{align-items:baseline;display:flex;flex-direction:row;flex-grow:1;gap:1rem;justify-content:space-between}._dropdownElement_itemCustomValue_196xa_1 small{font-size:.75rem;font-style:italic;line-height:1.2;margin-left:auto}`;
const styles = {
    get ['dropdownElement_tabIcon']() { injectStyles(key, css, options);  return '_dropdownElement_tabIcon_196xa_1'; },
    get ['dropdownElementTabIcon']() { injectStyles(key, css, options);  return '_dropdownElement_tabIcon_196xa_1'; },
    get ['dropdownElement_itemChevron']() { injectStyles(key, css, options);  return '_dropdownElement_itemChevron_196xa_1'; },
    get ['dropdownElementItemChevron']() { injectStyles(key, css, options);  return '_dropdownElement_itemChevron_196xa_1'; },
    get ['dropdownElement_itemIcon']() { injectStyles(key, css, options);  return '_dropdownElement_itemIcon_196xa_1'; },
    get ['dropdownElementItemIcon']() { injectStyles(key, css, options);  return '_dropdownElement_itemIcon_196xa_1'; },
    get ['dropdownElement_itemTitle']() { injectStyles(key, css, options);  return '_dropdownElement_itemTitle_196xa_1'; },
    get ['dropdownElementItemTitle']() { injectStyles(key, css, options);  return '_dropdownElement_itemTitle_196xa_1'; },
    get ['dropdownElement_itemCustomValue']() { injectStyles(key, css, options);  return '_dropdownElement_itemCustomValue_196xa_1'; },
    get ['dropdownElementItemCustomValue']() { injectStyles(key, css, options);  return '_dropdownElement_itemCustomValue_196xa_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
