// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '80e0d98fcd4589414538ceb8a913f4d4';
const css =`input._uikit-searchInput_djwoc_1{background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23AAA' stroke-linecap='round' stroke-linejoin='round' d='M2.5 8.333a5.834 5.834 0 1 0 11.667 0 5.834 5.834 0 0 0-11.667 0Zm15 9.167-5-5'/%3E%3C/svg%3E");background-position:.625rem .45rem;background-repeat:no-repeat;background-size:1.25rem;padding-left:2.25rem}input._uikit-searchInput_djwoc_1:hover:not(:disabled,:focus){background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23696969' stroke-linecap='round' stroke-linejoin='round' d='M2.5 8.333a5.834 5.834 0 1 0 11.667 0 5.834 5.834 0 0 0-11.667 0Zm15 9.167-5-5'/%3E%3C/svg%3E")}input._uikit-searchInput_djwoc_1:focus{background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-linejoin='round' d='M2.5 8.333a5.834 5.834 0 1 0 11.667 0 5.834 5.834 0 0 0-11.667 0Zm15 9.167-5-5'/%3E%3C/svg%3E")}input._uikit-searchInput_djwoc_1::-webkit-search-cancel-button,input._uikit-searchInput_djwoc_1::-webkit-search-decoration,input._uikit-searchInput_djwoc_1::-webkit-search-results-button,input._uikit-searchInput_djwoc_1::-webkit-search-results-decoration{display:none!important}`;
const styles = {
    get ['uikit-searchInput']() { injectStyles(key, css, options);  return '_uikit-searchInput_djwoc_1'; },
    get ['uikitSearchInput']() { injectStyles(key, css, options);  return '_uikit-searchInput_djwoc_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
