// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'b7b74abb6eda174baf39e4e8ea605d21';
const css =`._notification_1jmuv_1{align-items:center;border-radius:.25rem;border-width:1px;-moz-column-gap:.25rem;column-gap:.25rem;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:flex-start;min-height:2.25rem;padding:.375rem .75rem .375rem .625rem}._notification__info_1jmuv_1,._notification__warning_1jmuv_1{--tw-bg-opacity:0.05;--tw-text-opacity:1;background-color:rgb(194 154 6/var(--tw-bg-opacity));color:rgb(194 154 6/var(--tw-text-opacity))}._notification__error_1jmuv_1{--tw-bg-opacity:0.05;--tw-text-opacity:1;background-color:rgb(168 54 5/var(--tw-bg-opacity));color:rgb(168 54 5/var(--tw-text-opacity))}._notification__default_1jmuv_1{--tw-border-opacity:1;--tw-bg-opacity:1;--tw-text-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity));border-color:rgb(238 238 238/var(--tw-border-opacity));color:rgb(105 105 105/var(--tw-text-opacity))}._notification__sm_1jmuv_1{font-size:.813rem;line-height:1.4}._notification_icon_1jmuv_1{color:currentColor;flex-grow:0;flex-shrink:0;height:1.25rem;opacity:.7;width:1.25rem}._notificationIcon_1jmuv_1{display:inline-block;height:1.25rem;max-width:20rem;width:1.25rem}._notificationIcon_icon_1jmuv_1{stroke:currentColor;height:1.25rem;width:1.25rem}._notificationIcon__info_1jmuv_1{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity))}._notificationIcon__warning_1jmuv_1{--tw-text-opacity:1;color:rgb(194 154 6/var(--tw-text-opacity))}._notificationIcon__error_1jmuv_1{--tw-text-opacity:1;color:rgb(168 54 5/var(--tw-text-opacity))}._notificationIcon__default_1jmuv_1{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity))}`;
const styles = {
    get ['notification']() { injectStyles(key, css, options);  return '_notification_1jmuv_1'; },
    get ['notification__info']() { injectStyles(key, css, options);  return '_notification__info_1jmuv_1'; },
    get ['notificationInfo']() { injectStyles(key, css, options);  return '_notification__info_1jmuv_1'; },
    get ['notification__warning']() { injectStyles(key, css, options);  return '_notification__warning_1jmuv_1'; },
    get ['notificationWarning']() { injectStyles(key, css, options);  return '_notification__warning_1jmuv_1'; },
    get ['notification__error']() { injectStyles(key, css, options);  return '_notification__error_1jmuv_1'; },
    get ['notificationError']() { injectStyles(key, css, options);  return '_notification__error_1jmuv_1'; },
    get ['notification__default']() { injectStyles(key, css, options);  return '_notification__default_1jmuv_1'; },
    get ['notificationDefault']() { injectStyles(key, css, options);  return '_notification__default_1jmuv_1'; },
    get ['notification__sm']() { injectStyles(key, css, options);  return '_notification__sm_1jmuv_1'; },
    get ['notificationSm']() { injectStyles(key, css, options);  return '_notification__sm_1jmuv_1'; },
    get ['notification_icon']() { injectStyles(key, css, options);  return '_notification_icon_1jmuv_1'; },
    get ['notificationIcon']() { injectStyles(key, css, options);  return '_notificationIcon_1jmuv_1'; },
    get ['notificationIcon_icon']() { injectStyles(key, css, options);  return '_notificationIcon_icon_1jmuv_1'; },
    get ['notificationIconIcon']() { injectStyles(key, css, options);  return '_notificationIcon_icon_1jmuv_1'; },
    get ['notificationIcon__info']() { injectStyles(key, css, options);  return '_notificationIcon__info_1jmuv_1'; },
    get ['notificationIconInfo']() { injectStyles(key, css, options);  return '_notificationIcon__info_1jmuv_1'; },
    get ['notificationIcon__warning']() { injectStyles(key, css, options);  return '_notificationIcon__warning_1jmuv_1'; },
    get ['notificationIconWarning']() { injectStyles(key, css, options);  return '_notificationIcon__warning_1jmuv_1'; },
    get ['notificationIcon__error']() { injectStyles(key, css, options);  return '_notificationIcon__error_1jmuv_1'; },
    get ['notificationIconError']() { injectStyles(key, css, options);  return '_notificationIcon__error_1jmuv_1'; },
    get ['notificationIcon__default']() { injectStyles(key, css, options);  return '_notificationIcon__default_1jmuv_1'; },
    get ['notificationIconDefault']() { injectStyles(key, css, options);  return '_notificationIcon__default_1jmuv_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
