// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'ebc0866384047698e8e9867a3ebf5330';
const css =`._container_t4dew_1{cursor:pointer;padding-bottom:.25rem;padding-top:.75rem}._container__collapsed_t4dew_1{--tw-gradient-from:transparent var(--tw-gradient-from-position);--tw-gradient-to:transparent var(--tw-gradient-to-position);--tw-gradient-stops:var(--tw-gradient-from),var(--tw-gradient-to);--tw-gradient-to:#fff var(--tw-gradient-to-position);--tw-gradient-stops:var(--tw-gradient-from),var(--tw-gradient-to,transparent) 40%;background-image:linear-gradient(to bottom,var(--tw-gradient-stops));margin-top:-2.5rem;padding-top:2.5rem;position:relative;z-index:10}._button_t4dew_1{--tw-text-opacity:1;align-items:center;border-bottom-width:1px;border-color:transparent;color:rgb(13 121 194/var(--tw-text-opacity));display:flex;flex-direction:row;gap:.25rem;padding:.125rem 0}._button_t4dew_1 svg{height:1rem;width:1rem}._button_t4dew_1:hover,._container_t4dew_1:hover button{--tw-border-opacity:1;border-color:rgb(13 121 194/var(--tw-border-opacity));border-style:dashed}`;
const styles = {
    get ['container']() { injectStyles(key, css, options);  return '_container_t4dew_1'; },
    get ['container__collapsed']() { injectStyles(key, css, options);  return '_container__collapsed_t4dew_1'; },
    get ['containerCollapsed']() { injectStyles(key, css, options);  return '_container__collapsed_t4dew_1'; },
    get ['button']() { injectStyles(key, css, options);  return '_button_t4dew_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
