// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '67deb0c93fe9206b678d0d1650d8f8cf';
const css =`._image_1kt7c_1{max-width:inherit}._image__rounded_1kt7c_1{border-radius:.125rem}._image__sm_1kt7c_1{height:1rem;width:1rem}._image__md_1kt7c_1{height:1.5rem;width:1.5rem}._image__lg_1kt7c_1{height:2rem;width:2rem}._image__xl_1kt7c_1{height:2.5rem;width:2.5rem}._image__lg_1kt7c_1._image__rounded_1kt7c_1,._image__xl_1kt7c_1._image__rounded_1kt7c_1{border-radius:.375rem}`;
const styles = {
    get ['image']() { injectStyles(key, css, options);  return '_image_1kt7c_1'; },
    get ['image__rounded']() { injectStyles(key, css, options);  return '_image__rounded_1kt7c_1'; },
    get ['imageRounded']() { injectStyles(key, css, options);  return '_image__rounded_1kt7c_1'; },
    get ['image__sm']() { injectStyles(key, css, options);  return '_image__sm_1kt7c_1'; },
    get ['imageSm']() { injectStyles(key, css, options);  return '_image__sm_1kt7c_1'; },
    get ['image__md']() { injectStyles(key, css, options);  return '_image__md_1kt7c_1'; },
    get ['imageMd']() { injectStyles(key, css, options);  return '_image__md_1kt7c_1'; },
    get ['image__lg']() { injectStyles(key, css, options);  return '_image__lg_1kt7c_1'; },
    get ['imageLg']() { injectStyles(key, css, options);  return '_image__lg_1kt7c_1'; },
    get ['image__xl']() { injectStyles(key, css, options);  return '_image__xl_1kt7c_1'; },
    get ['imageXl']() { injectStyles(key, css, options);  return '_image__xl_1kt7c_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
