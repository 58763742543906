// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '08d65a6a6bfa19ab9da2d9b36743a36e';
const css =`._input_qy29a_1,._wrapper_qy29a_1{color:currentColor}._wrapper_qy29a_1{cursor:default;display:inline-block;position:relative}._span_qy29a_1{--placeholder-text:"Enter some text";display:block;visibility:visible}._span_qy29a_1:empty:after{--tw-text-opacity:1;color:rgb(156 163 175/var(--tw-text-opacity));content:var(--placeholder-text)}._wrapperRequired_qy29a_1 ._span_qy29a_1:empty:after{--tw-text-opacity:1;color:rgb(153 27 27/var(--tw-text-opacity))}._input_qy29a_1{border-top-color:transparent!important;bottom:0;left:0;margin:0;opacity:0;padding:0;position:absolute;right:0;top:0;transition-duration:.15s;transition-property:color,background-color,border-color,text-decoration-color,fill,stroke;transition-timing-function:cubic-bezier(.4,0,.2,1);width:100%}._input_qy29a_1:focus,._input_qy29a_1:hover{opacity:1}._input_qy29a_1:focus{outline:2px solid transparent;outline-offset:2px}._input_qy29a_1:focus+._span_qy29a_1,._wrapper_qy29a_1:hover ._span_qy29a_1{visibility:hidden}._wrapper_qy29a_1:hover ._input_qy29a_1{visibility:visible}`;
const styles = {
    get ['input']() { injectStyles(key, css, options);  return '_input_qy29a_1'; },
    get ['wrapper']() { injectStyles(key, css, options);  return '_wrapper_qy29a_1'; },
    get ['span']() { injectStyles(key, css, options);  return '_span_qy29a_1'; },
    get ['wrapperRequired']() { injectStyles(key, css, options);  return '_wrapperRequired_qy29a_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
