// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '78940e84a97584ae3d8f6c635ee62046';
const css =`._button_1i4ob_1{border-radius:.25rem}._button_1i4ob_1:focus-visible,._button_1i4ob_1:hover{--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity))}._schemaType_1i4ob_1{--tw-text-opacity:1;align-items:center;color:rgb(170 170 170/var(--tw-text-opacity));display:flex;flex-direction:row;gap:.125rem;padding-left:.25rem;padding-right:.25rem}._button_1i4ob_1 ._schemaType_1i4ob_1{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity))}._schemaType_icon_1i4ob_1{height:1rem;transform:translateY(1px);width:.75rem}._menu_1i4ob_1{align-items:flex-start;display:flex;flex-direction:row;flex-shrink:0;gap:1.25rem;justify-content:flex-start;margin:.25rem -.25rem}._menuList_1i4ob_1{display:flex;flex-direction:column;width:12rem}._menuHeader_1i4ob_1{--tw-text-opacity:1;color:rgb(170 170 170/var(--tw-text-opacity));font-size:.75rem;font-weight:700;line-height:1.4;margin:0;padding:.5rem .625rem}._menuItem_1i4ob_1{border-radius:.25rem;display:flex;flex-direction:row;gap:.5rem;padding:.5rem .625rem}._menuItem_1i4ob_1:hover,._menuItem_1i4ob_1[data-headlessui-state=active]{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity));font-weight:700}._menuItem_1i4ob_1[aria-current=true]{font-weight:700}._menuItem_icon_1i4ob_1{height:1.25rem;width:1.25rem}`;
const styles = {
    get ['button']() { injectStyles(key, css, options);  return '_button_1i4ob_1'; },
    get ['schemaType']() { injectStyles(key, css, options);  return '_schemaType_1i4ob_1'; },
    get ['schemaType_icon']() { injectStyles(key, css, options);  return '_schemaType_icon_1i4ob_1'; },
    get ['schemaTypeIcon']() { injectStyles(key, css, options);  return '_schemaType_icon_1i4ob_1'; },
    get ['menu']() { injectStyles(key, css, options);  return '_menu_1i4ob_1'; },
    get ['menuList']() { injectStyles(key, css, options);  return '_menuList_1i4ob_1'; },
    get ['menuHeader']() { injectStyles(key, css, options);  return '_menuHeader_1i4ob_1'; },
    get ['menuItem']() { injectStyles(key, css, options);  return '_menuItem_1i4ob_1'; },
    get ['menuItem_icon']() { injectStyles(key, css, options);  return '_menuItem_icon_1i4ob_1'; },
    get ['menuItemIcon']() { injectStyles(key, css, options);  return '_menuItem_icon_1i4ob_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
