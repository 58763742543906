// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'f6ac3ec7a855913003655b642fb964fc';
const css =`@layer elements{._row_1igsh_1{transition-duration:.15s;transition-property:color,background-color,border-color,text-decoration-color,fill,stroke;transition-timing-function:cubic-bezier(.4,0,.2,1)}._rowDisabled_1igsh_1{--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity))}._rowDisabled_1igsh_1,._rowDisabled_1igsh_1 ._input_1igsh_1,._rowDisabled_1igsh_1 ._textarea_1igsh_1{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity))}._rowDisabled_1igsh_1 ._input_1igsh_1,._rowDisabled_1igsh_1 ._textarea_1igsh_1{background-color:transparent}._row_1igsh_1:focus-within ._cell_1igsh_1{--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity))}._row_1igsh_1 ._cell_1igsh_1:has(._input_1igsh_1){padding-bottom:.35rem;padding-top:.35rem}._row_1igsh_1 ._cell_1igsh_1:has(._textarea_1igsh_1){padding:0}._row_1igsh_1 ._cell_1igsh_1:has(._textarea_1igsh_1) ._textarea_1igsh_1,._row_1igsh_1 ._cell_1igsh_1:has(._textarea_1igsh_1)>[class*=textareaWrapper]:after{form-sizing:normal;padding-bottom:.75rem;padding-top:.75rem;width:100%}._simpleRadio_1igsh_1{align-items:flex-start;cursor:pointer;display:flex;gap:.25rem;width:-moz-fit-content;width:fit-content}._simpleRadio_radio_1igsh_1{margin-top:.13rem}}`;
const styles = {
    get ['row']() { injectStyles(key, css, options);  return '_row_1igsh_1'; },
    get ['rowDisabled']() { injectStyles(key, css, options);  return '_rowDisabled_1igsh_1'; },
    get ['input']() { injectStyles(key, css, options);  return '_input_1igsh_1'; },
    get ['textarea']() { injectStyles(key, css, options);  return '_textarea_1igsh_1'; },
    get ['cell']() { injectStyles(key, css, options);  return '_cell_1igsh_1'; },
    get ['simpleRadio']() { injectStyles(key, css, options);  return '_simpleRadio_1igsh_1'; },
    get ['simpleRadio_radio']() { injectStyles(key, css, options);  return '_simpleRadio_radio_1igsh_1'; },
    get ['simpleRadioRadio']() { injectStyles(key, css, options);  return '_simpleRadio_radio_1igsh_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
