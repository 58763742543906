// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'af3383a8eba44f38eeba5ada62881e8e';
const css =`@layer elements{._uikit-radio_f2y1p_1{--tw-border-opacity:1;--tw-bg-opacity:1;align-self:baseline;-webkit-appearance:none;-moz-appearance:none;appearance:none;background-color:rgb(255 255 255/var(--tw-bg-opacity));border-color:rgb(105 105 105/var(--tw-border-opacity));border-radius:9999px;border-style:solid;border-width:1px;cursor:pointer;display:inline-block;height:1rem;margin:0 .375rem 0 0;position:relative;width:1rem}._uikit-radio__inline_f2y1p_1{transform:translateY(.15rem);vertical-align:baseline}._uikit-radio__checked_f2y1p_1{background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='2.5'/%3E%3C/svg%3E");background-position:50%;background-repeat:no-repeat;background-size:100% 100%}._uikit-radio_f2y1p_1:active{--tw-border-opacity:1;border-color:rgb(0 0 0/var(--tw-border-opacity))}._uikit-radio_f2y1p_1:disabled{--tw-border-opacity:1;border-color:rgb(170 170 170/var(--tw-border-opacity))}._uikit-radio_f2y1p_1:disabled:checked{background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 10 10' fill='%23aaa' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='2.5'/%3E%3C/svg%3E")}._uikit-radio_f2y1p_1:hover:not(:disabled,:active){--tw-border-opacity:1;--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity));border-color:rgb(0 0 0/var(--tw-border-opacity))}}`;
const styles = {
    get ['uikit-radio']() { injectStyles(key, css, options);  return '_uikit-radio_f2y1p_1'; },
    get ['uikitRadio']() { injectStyles(key, css, options);  return '_uikit-radio_f2y1p_1'; },
    get ['uikit-radio__inline']() { injectStyles(key, css, options);  return '_uikit-radio__inline_f2y1p_1'; },
    get ['uikitRadioInline']() { injectStyles(key, css, options);  return '_uikit-radio__inline_f2y1p_1'; },
    get ['uikit-radio__checked']() { injectStyles(key, css, options);  return '_uikit-radio__checked_f2y1p_1'; },
    get ['uikitRadioChecked']() { injectStyles(key, css, options);  return '_uikit-radio__checked_f2y1p_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
