// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '934f7cb7b50702d0844720ef8c949b3f';
const css =`._propertyList_cpjau_1{display:flex;flex-wrap:wrap;font-size:.813rem;gap:.5rem;line-height:1.4;padding-left:1rem}._propertyList_cpjau_1:empty{display:none}._propertyWrapper_cpjau_1{--tw-border-opacity:1;border-color:rgb(221 221 221/var(--tw-border-opacity));border-radius:.25rem;border-width:1px;display:inline-flex;gap:.25rem}._property_cpjau_1{padding:.125rem .5rem}._propertyName_cpjau_1{--tw-text-opacity:1;color:rgb(170 170 170/var(--tw-text-opacity))}._propertyValue_cpjau_1{--tw-text-opacity:1;color:rgb(0 0 0/var(--tw-text-opacity))}._propertyName_cpjau_1+._propertyValue_cpjau_1{margin-left:.25rem}._propertiesInModal_cpjau_1{--tw-border-opacity:1;border-bottom-left-radius:.25rem;border-bottom-right-radius:.25rem;border-color:rgb(221 221 221/var(--tw-border-opacity));border-width:0 1px 1px;display:block;font-size:.813rem;line-height:1.4;max-width:calc(100% - .5rem);overflow:auto;width:100%;width:-moz-fit-content;width:fit-content}._propertiesInModal_cpjau_1:first-child{border-radius:.25rem;border-top-width:1px}`;
const styles = {
    get ['propertyList']() { injectStyles(key, css, options);  return '_propertyList_cpjau_1'; },
    get ['propertyWrapper']() { injectStyles(key, css, options);  return '_propertyWrapper_cpjau_1'; },
    get ['property']() { injectStyles(key, css, options);  return '_property_cpjau_1'; },
    get ['propertyName']() { injectStyles(key, css, options);  return '_propertyName_cpjau_1'; },
    get ['propertyValue']() { injectStyles(key, css, options);  return '_propertyValue_cpjau_1'; },
    get ['propertiesInModal']() { injectStyles(key, css, options);  return '_propertiesInModal_cpjau_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
