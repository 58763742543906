// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'f38d1673736a90cf5759e72c894402b1';
const css =`._backdrop_61wfv_1{--tw-bg-opacity:0.8;align-items:flex-start;background-color:rgb(0 0 0/var(--tw-bg-opacity));bottom:0;display:flex;left:0;padding:1.75rem;position:absolute;right:0;top:0;z-index:500}._backdrop__clickable_61wfv_1{cursor:pointer}`;
const styles = {
    get ['backdrop']() { injectStyles(key, css, options);  return '_backdrop_61wfv_1'; },
    get ['backdrop__clickable']() { injectStyles(key, css, options);  return '_backdrop__clickable_61wfv_1'; },
    get ['backdropClickable']() { injectStyles(key, css, options);  return '_backdrop__clickable_61wfv_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
